<template>
    <div class="about-asyou-page page-p-t">
        <div class="header">
            <img class="user" src="@/assets/img/aboutAsyou/user.png"/>
            <div class="clearfix">
                <span class="color-word">我们, 使之成为可能</span>
                <p class="yi">一</p>
                <p class="breif">
                    <span>通过提供一站式虚拟人解决方案</span>
                    <span>帮助企业实现人格化、年轻化、数字化转型</span>
                    <span>我们以“搭建虚拟人联盟生态，引领虚拟人市场走向成熟”为使命</span>
                    <span>以“建设全球首个虚拟人三维内容社区”为发展愿景</span>
                </p>
                <p class="breif-right">
                    <span>爱希游科技成立于2022年，致力于让每个人都可以免费拥有</span>
                    <span>高质量虚拟人且有丰富的应用场景，并逐步让虚拟人深度参</span>
                    <span>与到人类的生活与生产之中, 打造虚拟人与现实人虚实交融和</span>
                    <span>谐共生的跨次元生态。</span>
                </p>
            </div>
        </div>
        <div class="join-us">
            <el-row>
                <el-col :span="12">
                    <h3>加入我们</h3>
                    <img @click="lookAll" class="all-img" src="@/assets/img/aboutAsyou/check_all.png"/>
                </el-col>
                <el-col :span="12">
                    <img class="right-img" src="@/assets/img/aboutAsyou/joinUs.png"/>
                </el-col>
                <el-col :span="24">
                    <p class="line"></p>
                </el-col>
                
            </el-row>
        </div>
        <ContactInformation></ContactInformation>
    </div>
</template>
<script>
import ContactInformation from '@/components/footer.vue';

export default {
    name: 'aboutUs',
    components: {
        ContactInformation
    },
    data() {
        return {


        }
    },
    methods: {
        lookAll() {
            this.$router.push({
                path: '/asyou/join',
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
.about-asyou-page {
    background: black;
    overflow-y: scroll;

    .header {
        margin-left: $whole-lr;
        padding-bottom: 0.8rem;
        margin-right: 1.27rem;
        text-align: center;
        border-bottom: 1px solid rgba($color: #81848B, $alpha: 0.6);
    
        .user {
            width: 6.83rem;
            height: 7.39rem;
        }

        div {
            text-align: left;

            .yi {
                margin-top: 0.8rem;
                font-size: 0.16rem;

            }
            .breif {
                margin-top: 0.66rem;
                span {
                    display: block;
                    @include font-section;
                    margin-top: 0.1rem;
                    letter-spacing: 0.03rem;

                }
            }
            .breif-right {
                float: right;
                @include de-0-18;
                
                span {
                    display: block;
                    margin-top: 0.05rem;
                    letter-spacing: 0.03rem;
                }
                
            }

        }

        .color-word {
            display: inline-block;
            text-align: left;
            @include font-2;
            color: transparent;
            background: linear-gradient(to right, #9495FB 0%, #EAAAE4 50%,  #65BAFF 80%, #91BCFF 100%);
            background-clip: text;
        }
    }

    .join-us {
        margin-top: 1rem;
        margin-left: $whole-lr;
        margin-right: 1.27rem;
        padding-bottom: 1.8rem;

        h3 {
            @include font-2;
        }
        .all-img {
            height: 0.59rem;
            width: 1.94rem;
            margin-top: 0.32rem;
            cursor: pointer;
        }
        .right-img {
            width: 100%;
        }
        .line {
            width: 100%;
            height: 0.4rem;
            background: url(../../assets/img/aboutAsyou/hireline.svg) no-repeat; 
            background-size: 100% 100%;
            
        }
        
    }
}
   
</style>